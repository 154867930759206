import React from "react"
import styled from "styled-components"
import SEO from "../components/seo"
import { Layout } from "../components/Layout/Layout"
import { Invests } from "../components/Invests/Invests"
import { Row } from "reactstrap"
import { Slider } from "../components/Slider/Slider"

const H1 = styled.h1`
  padding: 0;
  margin-bottom: 2rem;
`

export default () => {
  return (
    <Layout>
      <SEO title="Inwestycje" />
      <Slider />
      <Row>
        <H1>Inwestycje</H1>
      </Row>
      <Invests />
    </Layout>
  )
}
